*,
*::before,
*::after {
  box-sizing: border-box;
}

img,
svg {
  vertical-align: top;
}

body,
html {
  margin: 0;
  padding: 0;
  font-family: "AmaticSC-regular", "AmaticSC-Bold";
}

p {
  margin: 0;
}

.page-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.content-wrap {
  flex: 1;
}

@font-face {
  font-family: "AmaticSC-regular";
  src: url("/src/assets/fonts/AmaticSC-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "AmaticSC-Bold";
  src: url("/src/assets/fonts/AmaticSC-Bold.ttf") format("truetype");
}
@font-face {
  font-family: "JosefinSans-Regular";
  src: url("/src/assets/fonts/JosefinSans-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "JosefinSans-Light";
  src: url("/src/assets/fonts/JosefinSans-Light.ttf") format("truetype");
}
@font-face {
  font-family: "FiraMono-Regular";
  src: url("/src/assets/fonts/FiraMono-Regular.ttf") format("truetype");
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  transition: background-color 5000s ease-in-out 0s;
  font-family: "FiraMono-Regular";
}

::-moz-selection {
  /* Code for Firefox */

  background-color: rgb(113, 72, 133, 0.4);
}

::selection {
  background-color: rgb(113, 72, 133, 0.4);
}
