.cardStyle {
  padding: 15px 20px;
  border: 2px solid rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  width: 100%;
  margin: 0 auto 10px auto;
}

.cardStyle-half {
  padding: 15px 20px;
  border: 2px solid rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  width: 45%;
}
.container {
  display: flex;
  justify-content: space-between;
}

.StripeElement--focus {
  border: 2px solid #714885;
}
